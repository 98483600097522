import React from "react";

import Header from './Header';

const Hero = (props) => (
  <div className="heroDiv">
    <Header />
    
    <div className="heroChildren">{props.children}</div>
   
  </div>
);

export default Hero;


