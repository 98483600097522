import React from 'react';


import HeaderBlack from './HeaderBlack';
import './Layout.css';

const Layout = (props) => (
  <>
  <div className="layout">
    <HeaderBlack />
    
    <div className="layoutChildren">{props.children}</div>
    
    
    </div>
   
    </>
);

export default Layout;