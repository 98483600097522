import React from "react";
import './About.css'

import Me from "../images/bw-portrait-w-noise.png";

export default function About(props) {
  return (
    <div className="container">

      <div className="photoContainer">
      {/* <img className="portrait" src={Me} alt='Mouzayan Photo'></img> */}
      </div>

    <div className="aboutTextContainer">
      <p className="aboutText">
        I am a Software Engineer who holds over 15 years 
        of design leadership experience in the Landscape 
        Architecture space. As both a Design Manager and 
        Developer, I am an expert at building complex physical 
        and digital structures, taking an agile approach to 
        translating conceptual ideas into functional applications. 
        I am energized by the opportunity to work for an organization 
        that develops beautifully designed solutions with real people in mind. 
         
      </p>
      </div>
      </div>
  );
}
