import React from 'react';
import BurgerMenu from '../images/burger_white.png';

import './Burger.css'

const Burger = ({ open, setOpen }) => {
  return (
    <img className="nav-toggle" open={open}
      onClick={() => setOpen(!open)} src={BurgerMenu} />
  );
}
export default Burger;