import React, { Component} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


import Cv from "../resume-pdf/mak-resume.pdf";


const StyledNav = styled.nav`
  background-color: transparent;
  position: fixed;
  top: 0em;
  right: 0;
  height: 15%;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  transition: transform 0.5s ease;
  z-index: 200;
  padding-right: 0em;
  padding-top: 0em;
  padding-left: 0em;
  padding-bottom: 0em;
  margin-right: 13.5em;
  display: flex;
  width: 28%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  a {
    display: flex;
    text-align: right;
    margin-bottom: 0em;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-family: "Muli", sans-serif;
    color: white;
    text-decoration: none;
    z-index: 200;
  }

  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
  @media (max-width: 1440px) {
    transform: ${({ open }) => (open ? "translateY(6%)" : "translateY(-150%)")};

  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */
  @media (max-width: 1345px) {
    transform: ${({ open }) => (open ? "translateY(0%)" : "translateY(-150%)")};
  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */
  @media (max-width: 1024px) {
    transform: ${({ open }) => (open ? "translateY(5%)" : "translateY(-150%)")};
    padding-top: 0rem;
    margin-right: 8em;
    width: 30%;
    a {
      font-size: 1em;
      line-height: 2em;
      font-weight: 500;
    }
  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */
  @media (max-width: 899px) {
    transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(200%)")};
    font-size: 0.3rem;
    line-height: 0.8em;
    padding-top: 0rem;
    margin-right: 20em;
    width: 40%;
  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */
  @media (max-width: 780px) {
    transform: unset;
    transform: ${({ open }) =>
      open ? "translateY(32%)" : "translateY(-150%)"};
    margin: unset;
    padding: unset;
    height: unset;
    width: unset;
    width: 20%;
    margin-right: 12.5em;
    padding-right: 0em;
    padding-top: 0em;
    padding-left: 0em;
    padding-bottom: 0em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: center;

    a {
      font-size: 3.5em;
      line-height: 2em;
      font-weight: 700;
    }
  }
  /* ================================================== */ 
  /* ================================================== */
  /* ================================================== */
  @media (max-width: 464px) {
    transform: unset;
    transform: ${({ open }) =>
      open ? "translateY(27%)" : "translateY(-150%)"};
    margin: unset;
    padding: unset;
    height: unset;
    width: unset;
    width: 20%;
    margin-right: 12.5em;
    padding-right: 0em;
    padding-top: 0em;
    padding-left: 0em;
    padding-bottom: 0em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: center;

    a {
      font-size: 4em;
      line-height: 2em;
      font-weight: 700;
    }
  }
`;


  const SideNav = ({ open }) => {
  

      return (
        <StyledNav className="navDiv" open={open}>
          <Link className="navLink" to="/about">
            <>About</>
          </Link>

          <a className="navLink" href={Cv} target="_blank">
            Resume
      </a>

          <Link className="navLink" to="/stack">
            <>Stack</>
          </Link>

          <Link className="navLink" to="/contact">
            <>Contact</>
          </Link>
        </StyledNav>
      )
    }
  

export default SideNav;
