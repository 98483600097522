import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <div className="footerDiv">
      <p>{" © "} Mouzayan Delbourgo {new Date().getFullYear()}
         </p>
    </div>
  );
}
export default Footer;