import React from "react";
import { Link } from "react-router-dom";

import Email from "../images/email.png";
import Github from "../images/GitHub.png";
import linkedIn from "../images/linkedIn.png";

import "./About.css";

export default function About(props) {
  return (
    <div className="contactContainer">
      <p className="contactText">
        I began exploring my interest in software development in August 2018.
        Prior to that, I designed urban landscapes connecting New Yorkers to
        green, open space. Drop me a line with ideas and suggestions, or simply
        to say hello.
      </p>

      <div className="contactLinks">
        <a href="mailto:mouzayan.delbourgo@gmail.com" target="_blank">
          <img className="mailIcon" src={Email} alt="email"></img>
        </a>

        <a href="https://www.linkedin.com/in/mouzayan" target="_blank">
          <img className="linkedinIcon" src={linkedIn} alt="linkedin"></img>
        </a>

        <a href="https://github.com/Mouzayan" target="_blank">
          <img className="githubIcon" src={Github} alt="github"></img>
        </a>
      </div>
    </div>
  );
}
