import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useOnClickOutside } from "../Hooks";

import Burger from "./Burger";
import SideNav from "./SideNav";
import "./Header.css";
import { render } from "@testing-library/react";

// export default function Header() {
  export default function Header() {


  const[open, setOpen] = useState(false);
  const node = useRef();
    
  useOnClickOutside(node, () => setOpen(false));
  
  
    return (
      <div className="headerContainer">
        <header>
          <div className="headerDiv">
            <div className="leftDiv">
              <Link to="/">
                <p className="homeLink">
                  Mouzayan <br /> Delbourgo{" "}
                </p>
              </Link>
              <p className="position">
                digital products with <br /> beauty and integrity{" "}
              </p>
            </div>

            <div className="rightDiv">
         
            <div className="rightDivSub" ref={node}>
              <Burger open={open} setOpen={setOpen} />
              <SideNav open={open} />
            </div>

            </div>
              
              
            </div>
          
        </header>
      </div>
    );
}

  


  

