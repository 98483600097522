import React from "react";

import Css from "../images/0_CSS3_logo.png";
import Express from "../images/0_Expressjs-logo.png";
import Git from "../images/0_git.png";
import github from "../images/0_github-logo.png";
import Html from "../images/0_html-logo.png";
import Mongo from "../images/0_mongo-db-logo.png";
import Postgres from "../images/0_Postgresql_logo.png";
import Js from "../images/0_JavaScript-logo.png";
import Jquery from "../images/0_JQuery.png";
import Node from "../images/0_node-logo.png";
import Rails from "../images/0_rails-logo.png";
import Rct from "../images/0_react-logo.png";

import "./Stack.css";

export default function Stack(props) {
  return (
    <div className="stackContainer">
      <div className="one">
        <div className="rowContainer1">
          <img className="stackIcon1" src={Rails} alt="Rails Logo"></img>
          <img className="stackIcon1" src={Mongo} alt="Mongo Logo"></img>
          <img className="stackIconJQ" src={Jquery} alt="Jquery Logo"></img>
          <img className="stackIcon1" src={Express} alt="Express Logo"></img>
        </div>
      </div>

      <div className="two">
        <div className="colorBand">
          <div className="rowContainer2">
            <img className="stackIcon2" src={Git} alt="Git Logo"></img>
            <img className="stackIcon2" src={Rct} alt="React Logo"></img>
            <img
              className="stackIconProg"
              src={Postgres}
              alt="Postgres Logo"
            ></img>
            <img className="stackIconHtml" src={Html} alt="Html Logo"></img>
          </div>
        </div>
      </div>

      <div className="three">
        <div className="rowContainer3">
          <img className="stackIcon3" src={github} alt="Github Logo"></img>
          <img className="stackIcon3" src={Node} alt="Node Logo"></img>
          <img className="stackIconJS" src={Js} alt="JS Logo"></img>
          <img className="stackIconJS" src={Css} alt="Css Logo"></img>
        </div>
      </div>
    </div>
  );
}
