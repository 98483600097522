import React from "react";

import './ImgComp.css'

function ImgComp({ src, src2, href, href2, target, text }) {
  let imgStyles = {
    height: "auto",
    width: 90 + "%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",

    transition: "0.5s",
  };

  return (
    <>
       <div className="ghbLnkContainer">
      <a href={href2} target={target}>
      <img
        className="ghbLnk"
        src={src2}
        alt="github-link"
      ></img>
      </a>
      </div> 
      
    
      <div className="projectImage"
        data-text={text}
      >
    <a href={href} target={target}>
      <img className="project-thumbnail"
        src={src}
        alt="slide-img"
            style={imgStyles}
          ></img>
        </a>
      </div>
      

    </>
  );
}

export default ImgComp;
