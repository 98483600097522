import React, { useState } from "react";

import ImgComp from "./ImgComp";
import i0 from "../images/student.png";
import i1 from "../images/1.png";
import i2 from "../images/2.png";
import i3 from "../images/3.png";
import i4 from "../images/4.png";
import i5 from "../images/5.png";
import GhIcon from "../images/GitHub_white.png";

import "./Slider.css";

function Slider() {
  let sliderArr = [
    <ImgComp
      src={i0}
      href={"https://amazing-brahmagupta-1e0267.netlify.app/"}
      src2={GhIcon}
      href2={" "}
      target={"_blank"}
      text={
        "Student was developed with React.JS, Javascript and CSS. The site is connected to Strapi, a headless CMS which is deployed to AWS."
      }
    />,
    <ImgComp
      src={i1}
      href={"http://dreamboat.surge.sh/"}
      src2={GhIcon}
      href2={"https://github.com/Mouzayan/dreamboat"}
      target={"_blank"}
      text={
        "Dreamboat is a full-stack, full-CRUD app prototype. It incorporates Auth and a RESTful JSON API. It was developed with React.JS, Ruby on Rails, PostgreSQL, Javascript and CSS."
      }
    />,

    <ImgComp
      src={i2}
      href={"https://an-choi.netlify.app/"}
      src2={GhIcon}
      href2={"https://github.com/Mouzayan/AnChoi"}
      target={"_blank"}
      text={
        "AnChoi is a full-stack app prototype developed with React.JS, Javascript and CSS. It is a collaboration with a UX designer for updating a restaurant's dated website."
      }
    />,
    <ImgComp
      src={i3}
      href={"http://red-squirrel.surge.sh/"}
      src2={GhIcon}
      href2={"https://github.com/Mouzayan/red-squirrel"}
      target={"_blank"}
      text={
        "Red Squirrel is a full-stack, full CRUD app prototype developed with React.js, MongoDB, Express.JS, HTML, CSS and Javascript. It incorporates a custom built API."
      }
    />,
    <ImgComp
      src={i4}
      href={"https://refrigeratorhaiku.netlify.app/"}
      src2={GhIcon}
      href2={"https://github.com/Mouzayan/Refrigerator-Haiku"}
      target={"_blank"}
      text={
        "Refrigerator Haiku was developed with React.JS, CSS and Storybook. The app prototype retrives data from a dictionary API."
      }
    />,
    <ImgComp
      src={i5}
      href={"http://newsmasher.surge.sh/"}
      src2={GhIcon}
      href2={"https://github.com/Mouzayan/newSmasher"}
      target={"_blank"}
      text={
        "newSmasher is an app prototype developed with Javascript, HTML and CSS. Data is retrieved from newsapi.org."
      }
    />,
  ];

  const [x, setX] = useState(0);
  const [n, setN] = useState(1);

  const goLeft = () => {
    x === 0 ? setX(0) : setX(x + 103);

    if (n === 1) {
      setN(n);
    } else if ((n) => 2) {
      setN(n - 1);
    }
  };

  const goRight = () => {
    x === -103 * (sliderArr.length - 1) ? setX(0) : setX(x - 103);

    if (n === sliderArr.length) {
      setN(1);
    } else if ((n) => 1) {
      setN(n + 1);
    }
  };

  return (
    <div className="sliderContainer">
      <div className="sliderController">
        <p className="controllerDesc">
          {" "}
          {n} of 6 <br />
          <p className="descText"> App Prototypes </p>
          <button className="goLeft" onClick={goLeft}>
            Prev
          </button>
          <span className="fwrdSlash">/</span>
          <button className="goRight" onClick={goRight}>
            Next
          </button>
        </p>
      </div>

      <div className="slider">
        {sliderArr.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="slide"
                style={{ transform: `translateX(${x}%)` }}
              >
                {item}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Slider;
