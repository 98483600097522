import React, { Component } from "react";
import { Link } from "react-router-dom";


import "./Header.css";

class HeaderBlack extends Component {
  

  render() {
    
  return (
    <div className="headerContainerBlck">
      <header>
        <div className="headerDiv">
          <div className="leftDiv">
            <Link to="/">
              <p className="homeLinkBlck">
                Mouzayan <br /> Delbourgo{" "}
              </p>
            </Link>
            <p className="positionBlck">
              digital products with <br /> beauty and integrity{" "}
            </p>
          </div>

          <div className="rightDiv">
         
              
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default HeaderBlack;