import React, { Component } from "react";
// import { Route } from "react-router-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Home from "./Home";
import Contact from "./Contact";
import Stack from "./Stack";
import Layout from "./Layout";
import About from "./About";
import Hero from "./Hero";
import Slider from "./Slider";


export default class Main extends Component {
  // constructor(props) {
  //   super(props);
  // } 

  

  render() {
    return (
      <main>
        <Router>

        <Route
          exact
          path="/"
          render={() => (
            <>
              <Hero>
              </Hero>
              <Slider />
              </>
          )}
        />

        <Route
          // exact
          path="/about"
          render={() => (
            <>
              <Layout>
                <About />
              </Layout>
              
            </>
          )}
        />

        <Route
          // exact
          path="/contact"
          render={() => (
            <>
              <Layout>
              <Contact />
              </Layout>
            </>
          )}
        />

        <Route
          // exact
          path="/stack"
          render={() => (
            <>
              <Layout>
              <Stack />
              </Layout>
            </>
          )}
        />

       

    </Router>

      </main>
    );

  }
}

